import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { fetchPushMessageShortcut } from "@/api/pushnotifications/pushmessage/shortcut";
import { MessageShortcut } from "@/api/shortcut/typedefs";


export default /*@__PURE__*/_defineComponent({
  __name: 'PushMessageShortcut',
  setup(__props) {

const $q = useQuasar();
const route = useRoute();
const router = useRouter();

const pushMessageId = computed(() => {
  const rawValue = route.params["pushMessageId"];
  return Number(rawValue);
});

function routeToPushMessageView(pushMessageShortcut: MessageShortcut) {
  // TODO(PNS-2189) Use router.push once issue is fixed
  const redirectLocation = router.resolve({
    name: "PushMessage",
    params: {
      product: pushMessageShortcut.product,
      productLine: pushMessageShortcut.productLine,
      pushMessageId: pushMessageId.value,
    },
  });
  window.location.href = redirectLocation.href;
}

function redirectToPushMessageList() {
  router.push({
    name: "PushMessagesList",
    params: {
      product: 1,
      productLine: 1,
    },
  });
  $q.notify({
    type: "negative",
    message: `Failed to fetch push message with ID: ${pushMessageId.value}`,
  });
}

fetchPushMessageShortcut(pushMessageId.value)
  .then(routeToPushMessageView)
  .catch(redirectToPushMessageList);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})