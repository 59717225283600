import { MessageShortcut, MessageShortcutGuard } from "@/api/shortcut/typedefs";
import { httpClient, throwIfBadResponse } from "@/lib/http";
import { cast } from "@/lib/typing";

function convertPushMessageShortcut(data: unknown): MessageShortcut {
  return cast(MessageShortcutGuard, data);
}

export async function fetchPushMessageShortcut(
  id: number
): Promise<MessageShortcut> {
  const response = await httpClient.get(
    `/api/pushnotifications/message/${id}/`
  );

  await throwIfBadResponse(response);

  const result = await response.json();

  return convertPushMessageShortcut(result);
}
